@import "../theme/globals";
@import "../assets/fonts/atkinson.scss";
@import "../assets/fonts/bungee.scss";


.wrapper {
  margin: 2rem;
}

@media (max-width: 768px) {
  .wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

.info {
  h3 {
    color: $color-secondary;
  }
  h4 {
    color: $color-primary;
    font-family: 'Atkinson Hyperlegible', sans-serif;

  }
  img {
    margin: 1rem;
  }

  .phone {
    width: 100%;
  }
}


.costs {
  text-align: center;
  padding: 2rem;
  min-height: 10rem;
  background-color: $color-secondary-light;
  background-image: url("../assets/judoka-1920.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  color: $color-text-inv;

  p {
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0;
  }

  .cbig {
    font-size: 5rem;
    line-height: 5rem;
    color: $color-primary;
    font-family: 'Bungee', cursive;
  }

  .cfooter {
    font-size: 1rem;
    font-weight: lighter;
  }
}

.lets-go {
  color: $color-text-inv;
  background-color: $color-secondary-light;
  background-image: url("../assets/letsgo.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  padding: 2rem;
  text-align: center;

  .phone,.email {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    background-size: 100% 100%;
  }

  .phone {
    background-image: url("../assets/phone.svg");
  }

  .email {
    background-image: url("../assets/email.svg");
  }

  p {
    font-size: 1.75rem;
    margin: 0;

    &.small {
      font-size: 1rem;
    }
  }

  h1 {
    color: $color-primary;
    font-size: 5rem;
    margin-top: 2rem;
    font-family: 'Bungee', cursive;
  }

  a {
    color: $color-text-inv;
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .lets-go {
    h1 {
      font-size: 3rem;
    }
  }


}
