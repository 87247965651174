@import "../theme/globals";

.feature {
  margin: 0;
  padding: 1rem 2rem;
  border-top: 1px solid $color-secondary-light;

  color: $color-secondary-light;

  font-weight: bold;
  font-size: 1.25rem;
  // font-family: 'Bungee', cursive;

  .check {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../assets/Check.svg");
    background-size: cover;
    float: left;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../assets/open_white.svg");
    background-size: cover;
    float: right;
  }

  .content {
    margin-top: 2rem;
    font-size: 1rem;
    font-family: 'Atkinson Hyperlegible', sans-serif;
    margin-left: 2rem;
    font-weight: normal;
  }

  img {
    float: right;
    max-width: 20vw;

    margin: 1rem;
    box-shadow: 0.5rem 0.5rem 2rem rgba(0,0,0,0.3);
  }

  &:hover {
    .icon {
      background-image: url("../assets/open_hover.svg");
    }
  }

  &.active {
    .icon {
      background-image: url("../assets/close_white.svg");
    }

    &:hover {
      .icon {
        background-image: url("../assets/close_hover.svg");
      }
    }
  }

}

