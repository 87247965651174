@import "../theme/globals";

.colored-item {
  background-color: $color-secondary-light;
  color: $color-text-inv;
  margin: 0;
  padding: 1rem 2rem;
  border: 1px solid white;
  font-size: 1.25rem;

  // font-family: 'Russo One', sans-serif;


  .number {
    float: left;
    display: inline-block;
    font-family: 'Bungee', cursive;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../assets/open.svg");
    background-size: cover;
    float: right;
  }

  .content {
    margin-top: 2rem;
    font-size: 1rem;
  }

  img {
    float: right;
    max-width: 20vw;
    margin: 1rem
  }

  &.active {
    background-color: $color-secondary;

    .icon {
      background-image: url("../assets/close.svg");
    }
  }

  &:hover {
    background-color: $color-primary;
    .icon {
      background-image: url("../assets/open_hover.svg");
    }

    &.active {
      .icon {
        background-image: url("../assets/close_hover.svg");
      }
    }
  }
}
