.App {
  /*text-align: center;*/
}
@import "./theme/globals";

.header {
  text-align: center;
  min-height: 12rem;
  padding-top: 3rem;

  color: $color-text-inv;
  background-color: $color-primary;
  background-image: url("./assets/judoka-1920.jpg");
  background-blend-mode: multiply;
  background-size: cover;

  h1 {
    font-size: 5rem;
  }
}



.copyright {
  padding: 2rem;
  text-align: center;
  color: $color-secondary-light;

  img {
    width: 8rem;
  }

  h1 {
    font-family: 'Bungee', cursive;
    font-size: 4rem;
    line-height: 4rem;
    font-weight: bolder;

  }
}

.dsgvo {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer {
  background-color: $color-secondary;
  color: $color-text-inv;
  margin: 0;
  padding: 2rem;
  p {
    margin: 0;
  }

  a {
    margin-top: 1rem;
    display: block;
    color: $color-text-inv;
    text-decoration: none;
  }
}


h2 {
  color: $color-primary;
  text-align: center;
}

@media (max-width: 768px) {
  .header {
    h1 {
      font-size: 3rem;
    }
  }

  .copyright {
    h1 {
      font-size: 3rem;
    }
  }
}

.spacer {
  clear: both;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

.loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: white;
}
